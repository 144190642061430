












import Vue from 'vue';

interface Candidate {
    rating: number | null
}

export default Vue.extend({
    name: 'CandidateRating',
    props: {
        value: { type: Object as () => Candidate, required: true },
        disabled: { type: Boolean, required: true }
    },
    methods:{
        async advance() {
            this.value.rating = this.value.rating === 1 ? null : 1;
            this.$emit('onChange', this.value.rating);
        },
        async consider() {
            this.value.rating = this.value.rating === 0 ? null : 0;
            this.$emit('onChange', this.value.rating);
        },
        async reject() {
            this.value.rating = this.value.rating === -1 ? null : -1;
            this.$emit('onChange', this.value.rating);
        }
    }
})
