






















































































































































































































import Vue from 'vue';
import api from '@/api';
import JobDetails from '@/models/JobDetails';
import UserSession from '@/models/UserSession';
import { JobPermissions } from '@/models/Permissions';
import { EffectivePrivileges } from '@/models/Privileges';
// components
import JobInviteModal from '@/components/job/JobInviteModal.vue';
import JobHeader from '@/components/modals/JobHeader.vue';
import VisibilityView from '@/components/job/VisibilityView.vue';
import JobDetailsView from '@/components/job/JobDetailsView.vue';
import JobDetailsSidebar from '@/components/job/JobDetailsSidebar.vue';
import CandidateListView from '@/components/candidate/CandidateListView.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

interface SessionVM extends UserSession {
  payload: JobListingVM;
}

interface JobListingVM extends JobDetails {
  listDate: Date | null;
  namedBenefits: string[];
}

interface Data {
  job: JobListingVM | null;
  viewId: number;
  currentView: number;
}

export default Vue.extend({
  name: 'ManageJobListing',
  components: {
    JobInviteModal,
    JobHeader,
    VisibilityView,
    JobDetailsView,
    JobDetailsSidebar,
    CandidateListView,
    BreadCrumb,
  },
  data(): Data {
    return {
      job: null,
      viewId: 0,
      currentView: 0,
    };
  },
  methods: {
    closeJobInviteModal() {
      this.$bvModal.hide('job-invite-modal');
    },
    switchView(id: number) {
      if (this.currentView != id) {
        this.currentView = id;
      }
    },
    editJob() {
      this.$router.push({ name: 'EditJob', params: { id: this.job!.id! } });
    },
  },
  computed: {
    location(): string {
      return this.job == null || this.job.location == null
        ? ''
        : `${this.job.location.city}, ${this.job.location.stateOrProvince} ${this.job.location.country}`;
    },
    currentStatus(): string {
      let status = `${this.job!.status == 0 ? 'Draft' : ''}${
        this.job!.status == 4 ? 'Closed' : ''
      }${(this.job!.status & 1) != 0 ? 'On Career Page' : ''}`;
      return status.length == 0
        ? `${(this.job!.status & 2) != 0 ? 'Advertised' : ''}`
        : `${status}${(this.job!.status & 2) != 0 ? ' & Advertised' : ''}`;
    },
    shareDisabled(): boolean {
      return (this.privileges.j & JobPermissions.Share) == 0;
    },
    editEnabled(): boolean {
      return (this.privileges.j & JobPermissions.Edit) != 0;
    },
    // candidatesDisabled(): boolean {
    //   return (this.privileges.c & CandidatePermissions.View) == 0;
    // },
    privileges(): EffectivePrivileges {
      return this.$store.getters.privileges;
    },
  },
  async mounted() {
    try {
      this.$store.commit('setIsLoading', true);
      let jobId = this.$route.params.id;
      let vw = this.$route.params.view || '0';
      this.currentView = Number.parseInt(vw);

      const rslt = await this.$http.get(`${api}/jobs/${jobId}/manage`);
      const rspn: SessionVM = rslt.data;

      this.job = rspn.payload;

      const session: UserSession = {
        token: rspn.token,
        primaryOrg: rspn.primaryOrg,
        currentOrg: rspn.currentOrg,
        currentPrivileges: rspn.currentPrivileges,
      };

      this.$store.commit('setUserSession', session);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    } finally {
      this.$store.commit('setIsLoading', false);
    }
  },
});
