






































































































import { UUID } from '@/models/Types';
import api from '@/api';
import Vue from 'vue';
import JobApplication from '@/models/JobApplication';
import { CandidatePermissions } from '@/models/Permissions';
// components
import CandidateRating from '@/components/candidate/CandidateRating.vue';
import ProgressBar from '@/components/ProgressBar.vue';

interface CandidateStatus {
  jobListingId: UUID;
  candidateId: UUID;
  status: number;
  rating: number | null;
}

interface Item {
  id: string;
  name: string;
}

interface Data {
  pipeline: Item[];
  priorStatus: number;
}

export default Vue.extend({
  name: 'CandidateListItem',
  components: {
    CandidateRating,
    ProgressBar,
  },
  props: {
    application: { type: Object as () => JobApplication, required: true },
    privileges: { type: Number as () => CandidatePermissions, required: true },
  },
  data(): Data {
    return {
      pipeline: [],
      priorStatus: 0,
    };
  },
  computed: {
    isUploading(): boolean {
      return this.$store.getters.isApplicantUploading(
        this.application.candidate?.id
      );
    },
    workflowDisabled(): boolean {
      return (this.privileges & CandidatePermissions.Workflow) == 0;
    },
    shareDisabled(): boolean {
      return (this.privileges & CandidatePermissions.Share) == 0;
    },
    notesDisabled(): boolean {
      return (this.privileges & CandidatePermissions.ViewNotes) == 0;
    },
    notateDisabled(): boolean {
      return (this.privileges & CandidatePermissions.Notate) == 0;
    },
    rateDisabled(): boolean {
      return (this.privileges & CandidatePermissions.Rate) == 0;
    },
    // resumeDisabled(): boolean {
    //     return (this.privileges & CandidatePermissions.ViewResume) == 0;
    // }
    hasNoteIndicator(): object {
      const count = this.application.candidate!.notes.filter(n => n.userId != '00000000-0000-0000-0000-000000000000').length;
      return {
        count: 0, // count,
        displayText: count >= 10 ? '9+' : count.toString()
      };
    }
  },
  methods: {
    showDetails() {
      this.$emit('onShowDetails', this.application);
    },
    shareCandidate() {
      this.$emit('onShareCandidate', this.application);
    },
    showNotes() {
      this.$emit('onShowNotes', this.application);
    },
    requestResume() {
      this.$emit('onResumeRequest', this.application);
    },
    requestQuestionaire() {
      this.$emit('onQuestionaireRequest', this.application);
    },
    formatDate(): string {
      if (this.application.startedOn == null) {
        return '';
      } else {
        const moment = require('moment');
        let m = moment(this.application.startedOn);
        return m.format('MMM D, YYYY');
      }
    },
    async updateStatus() {
      this.$emit('onStatusChange');
      await this.saveChanges();
    },
    async updateRating(rating: number | null) {
      if (rating === -1) {
        this.application.status = 128;
        this.$emit('onStatusChange');
      }
      await this.saveChanges();
    },
    async saveChanges() {
      try {
        let update: CandidateStatus = {
          jobListingId: this.application.jobListingId,
          candidateId: this.application.candidateId!,
          status: this.application.status,
          rating: this.application.rating,
        };

        const rslt = await this.$http.put(
          `${api}/candidates/${update.candidateId}/status/${update.jobListingId}`,
          update,
          { headers: { Accept: 'application/json' } }
        );
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },
  },
  mounted() {
    this.application.status = this.application.status || 0;
    this.pipeline = [
      { id: '0', name: 'New: Unread' },
      { id: '1', name: 'New: Read' },
      { id: '16', name: 'Evaluating' },
      { id: '18', name: 'Evaluating: Phone Screen' },
      { id: '20', name: 'Evaluating: Interview' },
      { id: '32', name: 'Offered' },
      { id: '64', name: 'Hired' },
      { id: '128', name: 'Not Hired' },
      { id: '136', name: 'Not Hired: Declined Offer' }
    ];

    this.priorStatus = this.application.status;
  },
});
