























































import Vue from 'vue';
import api from '@/api';
// components
import BusySpinner from '@/components/modals/BusySpinner.vue';

interface Data {
    isBusy: boolean;
    isInvalid: boolean;
    isReactivation: boolean;
    remainingDays: number;
}

export default Vue.extend({
    name: 'RightAdConfirmationModal',
    components: {
        BusySpinner
    },
    props: {
        companyId: { type: String, required: true },
        jobId: { type: String, required: true }
    },
    data(): Data {
        return {
            isBusy: false,
            isInvalid: false,
            isReactivation: false,
            remainingDays: 0
        }
    },
    computed: {
      showReactivation(): boolean {
        return this.isReactivation
      },
      showError(): boolean {
        return this.isInvalid;
      }
    },
    methods: {
        async purchaseRightAd() {
            try {
                this.isBusy = true;
                const rslt = await this.$http.post(`${api}/payments/rightad/${this.companyId}?job=${this.jobId}`);

                if(!rslt.data.processed) { // no purchase required due to preexisting right ad
                    this.isReactivation = true;
                    this.remainingDays = rslt.data.remainingDays;
                } else {
                  this.$emit('onComplete', this.isInvalid);
                }
                
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
                this.isInvalid = true;
            } finally {
                this.isBusy = false;
            }
        },
        closeOnReactivation() {
            this.$emit('onComplete', this.isInvalid)
        },
        close() {
            this.isInvalid = false;
            this.$emit('onClose');
        }
    }
})
