














































import Vue from 'vue';
import JobDetails from '@/models/JobDetails';

export default Vue.extend({
    name: 'JobDetailsSidebar',
    props: {
        job: { type: Object as () => JobDetails, required: true }
    },
    computed: {
        minrate(): string {
            return new Intl.NumberFormat(`en-US`, { currency: `USD`, style: 'currency' }).format(this.job.compensation.min);
        },
        maxrate(): string {
            return new Intl.NumberFormat(`en-US`, { currency: `USD`, style: 'currency' }).format(this.job.compensation.max);
        }
    }
})
