




























































































































import Vue from 'vue';
import api from '@/api';
import JobApplication from '@/models/JobApplication';
import CandidateNote from '@/models/CandidateNote';
import { CandidatePermissions } from '@/models/Permissions';

interface Data {
  showAdd: boolean;
  newNoteText: string;
  notes: CandidateNote[];
  isBusy: boolean;
}

export default Vue.extend({
  name: 'CandidateNotesModal',
  props: {
    application: { type: Object as () => JobApplication, required: true },
    privileges: { type: Number as () => CandidatePermissions, required: true },
  },
  data(): Data {
    return {
      showAdd: false,
      newNoteText: '',
      notes: [],
      isBusy: false,
    };
  },
  computed: {
    isUploading() {
      return this.$store.getters.isApplicantUploading(
        this.application?.candidate?.id
      );
    },
    notateDisabled(): boolean {
      return (this.privileges & CandidatePermissions.Notate) == 0;
    },
  },
  methods: {
    hide() {
      this.$emit('onHide'); //close the modal
    },
    formatDate(note: CandidateNote) {
      const moment = require('moment');
      let m = moment(note.postedOn);
      return m.format('MMM D, YYYY');
    },
    pickFile() {
      (this.$refs.vid_upload as HTMLInputElement).click();
    },
    async fileChanged(files: any) {
      if (files) {
        // connect loader to each user and add it to dialog below notes
        Object.values(files).forEach((file: any) => {
          if (!file) {
            return;
          }
          const formData = new FormData();
          formData.append('candidate', this.application?.candidateId!);
          formData.append('job', this.application?.jobListingId);
          formData.append('file', file, file.name);

          this.uploadVideo(formData);
        });
      }
    },
    async uploadVideo(form: FormData) {
      try {
        // this.isBusy = true;
        this.$store.commit(
          'setUploaderForApplicantId',
          this.application.candidate?.id
        );
        const rslt = await this.$http.post(`${api}/upload/video`, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        const path = rslt.data;
        console.log(`file saved to ${path}.`);
        // this.newNoteText = `<a href="${path}">Interview video</a>`;
        // await this.saveNote();
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      } finally {
        //  this.isBusy = false;
        this.$store.commit(
          'removeUploaderForApplicantId',
          this.application.candidate?.id
        );
        await this.getNotes();
      }
    },
    async saveNote() {
      try {
        let note: CandidateNote = {
          candidateId: this.application.candidateId!,
          jobListingId: this.application.jobListingId,
          rank: 0,
          message: this.newNoteText,
          authorName: null,
          postedOn: null,
        };

        this.newNoteText = '';

        const rslt = await this.$http.post(
          `${api}/candidates/${this.application.candidateId}/notes/add`,
          note,
          { headers: { Accept: 'application/json' } }
        );
        if (this.showAdd) {
          this.toggleNoteActions();
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }

      await this.getNotes();
    },
    async getNotes() {
      try {
        const rslt = await this.$http.get(
          `${api}/candidates/${this.application.candidateId}/notes/${this.application.jobListingId}`,
          { headers: { Accept: 'application/json' } }
        );
        this.notes = rslt.data;
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },
    toggleNoteActions() {
      this.showAdd = !this.showAdd;
    },
  },
  async mounted() {
    await this.getNotes();
  },
  destroyed() {
    this.showAdd = false;
    this.newNoteText = '';
    this.notes = [];
    this.isBusy = false;
  }
});
