














































import { UUID, NullableString } from '@/models/Types';
import Vue from 'vue';
import api from '@/api';
import JobApplication from '@/models/JobApplication';
// components
import ModalFooter from '@/components/modals/ModalFooter.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';
// validation
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('email', {
    ...email,
    message: "A recipient's email address is required"
})

interface CandidateShareVM {
    recipient: string | null,
    message: string | null,
    jobListingId: UUID,
    candidateId: UUID,
    applyDate: Date | null,
    resume: NullableString
}

interface Data {
    payload: CandidateShareVM | null
    isComplete: boolean | null
}

export default Vue.extend({
    name: 'CandidateShareModal',
    components: {
        MaterialInput,
        MaterialTextarea,
        ModalFooter,
        ValidationObserver,
        ValidationProvider
    },
    props: {
        app: { 
            type: Object as () => JobApplication, required: true 
        }
    },
    data(): Data {
        return {
            payload: null,
            isComplete: null
        }
    },
    computed: {
        isLoading(): boolean {
            return this.$store.getters.isLoading;
        },
    },
    methods: {
        close(){
            this.$emit('onClose'); //close the modal
        },
        hide() {
            this.$bvModal.hide('candidate-share-modal');
        },
        async send(){
            try{
                this.$store.commit('setIsLoading', true);
                // send the candidate payload
                const rslt = await this.$http.post(`${api}/share/candidate`, this.payload);
                this.isComplete = true;
                // eslint-disable-next-line
                console.log('email sent');

            } catch (error) {
                this.isComplete = false;
                // eslint-disable-next-line
                console.error(error);
            } finally {
                this.$store.commit('setIsLoading', false);
                await new Promise(r => setTimeout(r, 2000));
                this.close();
            }

        }
    },
    mounted() {
            
        this.payload = { 
                recipient: '', 
                message: '', 
                jobListingId: this.app.jobListingId, 
                candidateId: this.app.candidateId!,
                applyDate: this.app.completedOn,
                resume: this.app.resume 
            }
    }
})
