



































































































































































































import Vue from 'vue';
import api from '@/api';
import { UUID } from '@/models/Types';
import JobAbstract from '@/models/JobAbstract';
import JobApplication from '@/models/JobApplication';
import { CandidatePermissions } from '@/models/Permissions';
// components
import CandidateListItem from '@/components/candidate/CandidateListItem.vue';
import CandidateDetailsModal from '@/components/candidate/CandidateDetailsModal.vue';
import JobHeader from '@/components/modals/JobHeader.vue';
import CandidateShareModal from '@/components/candidate/CandidateShareModal.vue';
import CandidateNotesModal from '@/components/candidate/CandidateNotesModal.vue';

interface CandidateStatus {
    jobListingId: UUID
    candidateId: UUID
    status: number
    rating: number | null
}

interface Data {
    applicants: JobApplication[],
    partial: JobApplication[],
    received: JobApplication[],
    evaluating: JobApplication[],
    offered: JobApplication[],
    hired: JobApplication[],
    declined: JobApplication[],
    headers: boolean[],
    selectedApp: JobApplication | null,
    sortFilters: {
        byDate: boolean,
        byName: boolean,
        descending: boolean
    }
}

export default Vue.extend({
    name: 'CandidateListView',
    components: {
        CandidateDetailsModal,
        CandidateShareModal,
        CandidateNotesModal,
        CandidateListItem,
        JobHeader
    },
    props: {
        abstract: { type: Object as () => JobAbstract, required: true },
        privileges: { type: Number as () => CandidatePermissions, required: true }
    },
    data(): Data {
        return {
            applicants: [],
            partial: [],
            received: [],
            evaluating: [],
            offered: [],
            hired: [],
            declined: [],
            headers: [ true, true, true, true, true, true ],
            selectedApp: null,
            sortFilters: {
                byDate: true,
                byName: false,
                descending: true,
            }
        }
    },
    computed: {
        isDisabled(): boolean {
            return (this.privileges & CandidatePermissions.View) == 0;
        },
        enableNotifications(): boolean {
            return this.abstract.notify;
        }
    },
    methods: {
        changeStatus() { // redraw table
            this.collateCandidates(this.applicants);
        },
        collateCandidates(set: JobApplication[]){

            set.sort((a, b) => this.sortFilters.byDate ? a.status - b.status || this.sortByDate(a, b) : a.status - b.status || this.sortByName(a, b));

            this.partial = [];
            this.received = [];
            this.evaluating = [];
            this.offered = [];
            this.hired = [];
            this.declined = [];

            set.filter(e => {
                if((e.status & 1 << 4) != 0){ //16 - Evaluating
                    this.evaluating.push(e);
                } else if((e.status & 1 << 5) != 0) { // 32 - Offer
                    this.offered.push(e)
                } else if((e.status & 1 << 6) != 0) { // 64 - Hired
                    this.hired.push(e)
                } else if((e.status & 1 << 7) != 0) { // 128 - Declined
                    this.declined.push(e)
                } else if((e.completedOn == null)){
                    this.partial.push(e)
                } else {
                    this.received.push(e);
                }
            });
        },
        toggleDateSort() {
            if(this.sortFilters.byDate) {
                this.sortFilters.descending = !this.sortFilters.descending;
            } else {
                this.sortFilters.byName = false;
                this.sortFilters.byDate = true;
                this.sortFilters.descending = true;
            }

            // collate candidates
            this.collateCandidates(this.applicants);
        },
        toggleNameSort() {
            if(this.sortFilters.byName) {
                this.sortFilters.descending = !this.sortFilters.descending;
            } else { // date sort
                this.sortFilters.byDate = false;
                this.sortFilters.byName = true;
                this.sortFilters.descending = false;
            }

            // collate candidates
            this.collateCandidates(this.applicants);
        },
        async toggleNotifications() {

            try {
               const rslt = await this.$http.get(`${api}/jobs/${this.abstract.id}/notify`, { headers: { 'Accept':'application/json'}});

                if(rslt.data) {
                    this.abstract.notify = !this.abstract.notify;
                }

            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }
        },
        sortByDate(a: JobApplication, b: JobApplication): number {
            let d1 = a.startedOn != null ? new Date(a.startedOn!) : new Date(1000, 0, 1);
            let d2 = b.startedOn != null ? new Date(b.startedOn!) : new Date(1000, 0, 1);
            return this.sortFilters.descending ? d2.valueOf() - d1.valueOf() : d1.valueOf() - d2.valueOf();
        },
        sortByName(a: JobApplication, b: JobApplication): number {
            let n1 = `${a.candidate!.firstName} ${a.candidate!.lastName}`;
            let n2 = `${b.candidate!.firstName} ${b.candidate!.lastName}`;
            return this.sortFilters.descending ? n2.localeCompare(n1) : n1.localeCompare(n2);
        },
        showCandidateDetails(application: JobApplication) {
            this.selectedApp = application;
            this.$nextTick(function() {
                this.$bvModal.show('applicant-details');
            });
        },
        hideCandidateDetails() {
            this.selectedApp = null;
            this.$bvModal.hide('applicant-details');
        },
        showCandidateNotes(application: JobApplication) {
            this.selectedApp = application;
            this.$nextTick(function() {
                this.$bvModal.show('applicant-notes');
            });
        },
        async requestResume(application: JobApplication) {

            this.$bvToast.toast(`Sending ${application.candidate!.smsConsent ? 'SMS' : 'Email'} to: ${application.candidate!.smsConsent ? application.candidate!.phone : application.candidate!.email}`, {
                autoHideDelay: 6000,
                title: `Requesting Resume...`,
                variant: 'success',
                // target: 'b-toaster-top-center',
                solid: true,
                noCloseButton: true,
                appendToast: true
            });

            try {
                const rslt = await this.$http.put(`${api}/request/resume`, application, { headers: { 'Accept':'application/json'}});



            } catch (error) {
                // eslint-disable-next-line
                console.error(error);

                this.$bvToast.toast(`Failed to send ${application.candidate!.smsConsent ? 'SMS' : 'Email'} to: ${application.candidate!.smsConsent ? application.candidate!.phone : application.candidate!.email}. Please try again.`, {
                    autoHideDelay: 6000,
                    title: `Request Failed`,
                    variant: 'danger',
                    // target: 'b-toaster-top-center',
                    solid: true,
                    noCloseButton: true,
                    appendToast: true
                });

            }
        },
        async requestQuestionaire(application: JobApplication) {

            try {

                this.$bvToast.toast(`Sending ${application.candidate!.smsConsent ? 'SMS' : 'Email'} to: ${application.candidate!.smsConsent ? application.candidate!.phone : application.candidate!.email}`, {
                    autoHideDelay: 6000,
                    title: `Requesting Questionaire...`,
                    variant: 'success',
                    // target: 'b-toaster-top-center',
                    solid: true,
                    noCloseButton: true,
                    appendToast: true
                });

                const rslt = await this.$http.put(`${api}/request/questionaire`, application, { headers: { 'Accept':'application/json'}});

            } catch (error) {
                // eslint-disable-next-line
                console.error(error);

                this.$bvToast.toast(`Failed to send ${application.candidate!.smsConsent ? 'SMS' : 'Email'} to: ${application.candidate!.smsConsent ? application.candidate!.phone : application.candidate!.email}. Please try again.`, {
                    autoHideDelay: 6000,
                    title: `Request Failed`,
                    variant: 'danger',
                    // target: 'b-toaster-top-center',
                    solid: true,
                    noCloseButton: true,
                    appendToast: true
                });
            }
        },
        hideCandidateNotes() {
            this.selectedApp = null;
            this.$bvModal.hide('applicant-notes');
        },
        shareCandidate(application: JobApplication){
            this.selectedApp = application;
            this.$nextTick(function() {
                this.$bvModal.show('candidate-share-modal');
            });
        },
        closeCandidateShareModal() {
            this.selectedApp = null;
            this.$bvModal.hide('candidate-share-modal');
        },
        async updateRating(rating: number | null) { // rating update for details modal

            this.selectedApp!.rating = rating;
            if(rating === -1){
                this.selectedApp!.status = 128;
                this.changeStatus();
            }

            try{
                let update: CandidateStatus = { 
                    jobListingId: this.selectedApp!.jobListingId, 
                    candidateId: this.selectedApp!.candidateId!, 
                    status: this.selectedApp!.status, 
                    rating: this.selectedApp!.rating
                }

                await this.$http.put(`${api}/candidates/${update.candidateId}/status/${update.jobListingId}`, update, { headers: { 'Accept':'application/json'}});
                
            } catch (error){
                // eslint-disable-next-line
                console.error(error);
            }
        }
    },
    async mounted() {
        try{
            if(this.isDisabled) {
                return;
            }

            let jobId = this.$route.params.id;
            const rslt = await this.$http.get(`${api}/jobs/${jobId}/candidates`);
            this.applicants = rslt.data;

            // collate candidates
            this.collateCandidates(this.applicants);

        } catch (error){
            // eslint-disable-next-line
            console.error(error);
        }
    }
});
