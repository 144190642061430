























































































import Vue from "vue";
import api from "@/api";
import JobDetails from "@/models/JobDetails";
import { JobPermissions } from "@/models/Permissions";
// components
import RightAdConfirmationModal from '@/components/job/RightAdConfirmationModal.vue';

interface JobListingVM extends JobDetails {
  listDate: Date | null;
}

export default Vue.extend({
  name: "VisibilityView",
  components: {
    RightAdConfirmationModal
  },
  props: {
    job: { type: Object as () => JobListingVM, required: true },
    privileges: { type: Number as () => JobPermissions, required: true }
  },
  computed: {
    rightAdDisabled(): boolean {
        return (this.privileges & JobPermissions.Advertise) == 0;
    },
    publishDisabled(): boolean {
        return (this.privileges & JobPermissions.PublishToCareers) == 0;
    },
    deactivateDisabled(): boolean {
        return (this.privileges & JobPermissions.Deactivate) == 0;
    },
    isDraft(): boolean {
      return this.job.status == 0;
    },
    isCareerPaged(): boolean {
      return (this.job.status & 1) != 0;
    },
    isAdvertised(): boolean {
      return (this.job.status & 2) != 0;
    },
    isSevenDayAd(): boolean {
      return (this.job.status & 8) != 0;
    },
    isFifteenDayAd(): boolean {
      return (this.job.status & 16) != 0;
    },
    isJobCaseAd(): boolean {
      return (this.job.status & 32) != 0;
    },
    isVonq(): boolean {
      return (this.job.status & 64) != 0;
    },
    isGFJ(): boolean {
      return (this.job.status & 128) != 0;
    },
    isClosed(): boolean {
      return (this.job.status & 4) != 0;
    },
    showAdminControls(): boolean {
      return this.$store.getters.isWerkberry;
    }
  },
  methods: {
    async alterStatus(status: number) {

        if(status == 2 && !this.isAdvertised) {  // intercept rightAd buys
            this.$bvModal.show('rightad-confirm-modal');
            return;
        }

        await this.saveStatus(status);
    },
    async saveStatus(status: number) {
        try {
            this.$store.commit('setIsLoading', true);
            const rslt = await this.$http.put(`${api}/jobs/${this.job.id}/status`, status);
            this.job.status = rslt.data.status;
            this.job.listDate = rslt.data.listDate;
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        } finally {
           this.$store.commit('setIsLoading', false);
        }
    },
    async activateRightAd(fail: boolean) {
      
      if(fail) {
        this.abandonRightAd();
        return;
      }

      // activation succeeded - save status
      await this.saveStatus(2);
      this.$bvModal.hide('rightad-confirm-modal');
    },
    abandonRightAd() {
        let sw = this.$refs.rightadswitch as HTMLInputElement;
        sw.checked = false;
        this.$bvModal.hide('rightad-confirm-modal');
    }
  }
});
