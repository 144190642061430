

















































































import Vue from 'vue';
import JobDetails from '@/models/JobDetails'

interface JobListingVM extends JobDetails {
    listDate: Date | null
    namedBenefits: string[]
}

export default Vue.extend({
    name: 'JobDetailsView',
    props: {
        job: {
            type: Object as () => JobListingVM
        }
    }
})
