






















































import { UUID } from '@/models/Types';
import Vue from 'vue';
import api from '@/api';
import JobInvite, { DefaultJobInvite } from '@/models/JobInvite'
// components
import ModalFooter from '@/components/modals/ModalFooter.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';
// validation
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('email', {
    ...email,
    message: "A recipient's email address is required"
})

interface Data {
    invite: JobInvite | null;
    success: boolean | null;
    isProcessing: boolean;
}

export default Vue.extend({
    name: 'JobInviteModal',
    components: {
        MaterialInput,
        MaterialTextarea,
        ModalFooter,
        ValidationObserver,
        ValidationProvider
    },
    props: {
        jobId: {
            type: String as () => UUID, required: true 
        }
    },
    data(): Data {
        return {
            invite: null,
            success: null,
            isProcessing: false
        }
    },
    computed: {
        succeeded(): boolean | null {
            return this.success;
        }
    },
    methods: {
        close(){
            this.$emit('onClose'); //close the modal
        },
        async send(){
            try{
                this.isProcessing = true;
                // send the invite
                const rslt = await this.$http.post(`${api}/share/job`, this.invite);
                this.success = true;
                // eslint-disable-next-line
                console.log('email sent');

            } catch (error) {
                this.success = false;
                // eslint-disable-next-line
                console.error(error);
            } finally {
                this.isProcessing = false;
                await new Promise(r => setTimeout(r, 2500));
                this.invite = { ...DefaultJobInvite, resourceId: this.jobId };
                this.success = null;
                this.close();
            }
        }
    },
    mounted() {
        this.invite = { ...DefaultJobInvite, resourceId: this.jobId };
    }
})
