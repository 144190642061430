





































































































import Vue from 'vue';
import JobAbstract from '@/models/JobAbstract';
import JobApplication from '@/models/JobApplication';
import { CandidatePermissions } from '@/models/Permissions';
// components
import CandidateRating from '@/components/candidate/CandidateRating.vue';

export default Vue.extend({
    name: 'CandidateDetailsModal',
    components: {
        CandidateRating
    },
    props: {
        abstract: { type: Object as () => JobAbstract, required: true },
        application: {type: Object as () => JobApplication, required: true },
        privileges: { type: Number as () => CandidatePermissions, required: true }
    },
    computed: {
        shareDisabled(): boolean {
            return (this.privileges & CandidatePermissions.Share) == 0;
        },
        rateDisabled(): boolean {
            return (this.privileges & CandidatePermissions.Rate) == 0;
        },
        resumeDisabled(): boolean {
            return (this.privileges & CandidatePermissions.ViewResume) == 0;
        }
    },
    methods: {
        share(){
            this.$emit('onShareCandidate', this.application);
        },
        hide(){
            this.$emit('onHide'); //close the modal
        },
        async updateRating(rating: number | null){
            this.$emit("onUpdateRating", rating);
        }
    }
})
