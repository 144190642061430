import { UUID, NullableString } from './Types';

export default interface JobInvite {
    guestName: string,
    guestEmail: string,
    sponsorName: NullableString,
    resourceId: UUID | null,
    message: NullableString
}

export const DefaultJobInvite: JobInvite = { guestName: '', guestEmail: '', sponsorName: null, resourceId: null, message: null }